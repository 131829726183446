import { useCallback } from 'react';
import useLocalStorage from '@kk/shared/hooks/useLocalStorage';
import { handleSettledPromises } from '@kk/shared/utils/async';
import { chunks } from '@kk/shared/utils/iterables';
import {
  keepPreviousData,
  queryOptions,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import ms from 'ms';
import { RequestOpts } from '@/api/api-types';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import queryClient from '@/api/queryClient';
import type { CaseCardProps } from '@/components/CaseCard/CaseCard';
import { type Params } from '@/routes/CasePage/loader';

export type RecentlyViewedCase = Params;

const buster = import.meta.env.DEV ? 'dev' : __BUILD_ID__;
const recentlyViewedCasesKey = `recently-viewed-cases-${buster}` as const;
const MAX_ITEMS_IN_LOCAL_STORAGE = 3;

export function getRecentlyViewedCasesQuery(opts?: RequestOpts) {
  const queryKey = [recentlyViewedCasesKey];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => fetchRecentlyViewedCases({ ...opts, signal }),
    placeholderData: keepPreviousData,
    staleTime: 0,
    gcTime: ms('5m'),
  });
}

/**
 * Fetches the recently viewed cases asynchronously.
 *
 * @returns {Promise<Array<CaseCardProps>>} A promise that resolves to an array of CaseCardProps representing the recently viewed cases.
 */
export async function fetchRecentlyViewedCases(
  opts: RequestOpts = {},
): Promise<Array<CaseCardProps>> {
  const localStorageRecentlyViewedCases =
    localStorage.getItem(recentlyViewedCasesKey) ?? '[]';

  const parsedCases: RecentlyViewedCase[] = JSON.parse(
    localStorageRecentlyViewedCases,
  ) as RecentlyViewedCase[];

  // Fetch all the data in parallel to be reassembled later.
  const parallelFetches = parsedCases.flatMap(
    ({ companyId, contractId, caseId }) => [
      fetchCompanyQuery({ companyId }, opts),
      fetchLoanQuery({ contractId }, opts),
      fetchCaseQuery({ caseId }, opts),
      fetchCaseAssigneesQuery({ caseId }, opts),
    ],
  );

  const recentlyViewedCasesData = await handleSettledPromises(parallelFetches);

  const recentlyViewedCases = chunks(
    recentlyViewedCasesData,
    4, // 4 fetches per case card
  )
    .map<CaseCardProps>(
      ([company, loanInfo, eventCase, caseAssignees]: [
        Awaited<ReturnType<typeof fetchCompanyQuery>>,
        Awaited<ReturnType<typeof fetchLoanQuery>>,
        Awaited<ReturnType<typeof fetchCaseQuery>>,
        Awaited<ReturnType<typeof fetchCaseAssigneesQuery>>,
      ]) =>
        ({
          // reassemble the data to objects
          company,
          loanInfo,
          eventCase,
          caseAssignees,
        }) satisfies CaseCardProps,
    )
    .filter(
      (caseCardProps) =>
        // filter out results that contain errors in data fetching
        !Object.values(caseCardProps).some((value) => value instanceof Error),
    );

  return Promise.resolve(recentlyViewedCases);
}

export function fetchRecentlyViewedCasesQuery(
  opts: RequestOpts = {},
): Promise<Array<CaseCardProps>> {
  return queryClient.ensureQueryData(getRecentlyViewedCasesQuery(opts));
}

/**
 * Custom hook for fetching and sorting recently viewed cases.
 *
 * @param {UseQueryOptions<Array<CaseCardProps>>} [options] - Options for the useQuery hook.
 * @returns {QueryResult<Array<CaseCardProps>>} The query result object containing the recently viewed cases data.
 */
export function useRecentlyViewedCases(
  options: Omit<
    ReturnType<typeof getRecentlyViewedCasesQuery>,
    'queryKey'
  > = {},
): UseQueryResult<Array<CaseCardProps>> {
  return useQuery(
    Object.assign(getRecentlyViewedCasesQuery(), {
      ...options,
      placeholderData: keepPreviousData,
    }),
  );
}

/**
 * Returns recently viewed cases and a function to add a case to the recently viewed cases in local storage.
 *
 * @note Stores a maximum of 3 recently viewed cases in local storage.
 *
 * @returns {Object} An object with the following properties:
 *   - recentlyViewedCasesInLocalStorage {Array<RecentlyViewedCase>} - An array of recently viewed cases stored in local storage.
 *   - addRecentlyViewedCaseToLocalStorage {Function} - A function to add a case to the recently viewed cases in local storage.
 */
export function useRecentlyViewedCasesStorage(): {
  recentlyViewedCasesInLocalStorage: Array<RecentlyViewedCase>;
  addRecentlyViewedCaseToLocalStorage: (entry: RecentlyViewedCase) => void;
} {
  const [
    recentlyViewedCasesInLocalStorage = [],
    setRecentlyViewedCasesInLocalStorage,
  ] = useLocalStorage<Array<RecentlyViewedCase>>(recentlyViewedCasesKey, []);

  const addRecentlyViewedCaseToLocalStorage = useCallback(
    (entry: RecentlyViewedCase) => {
      if (
        recentlyViewedCasesInLocalStorage.some(
          (item) => item.caseId === entry.caseId,
        )
      ) {
        const newRecentlyViewedCases = recentlyViewedCasesInLocalStorage.filter(
          (item) => item.caseId !== entry.caseId,
        );
        newRecentlyViewedCases.unshift(entry);
        setRecentlyViewedCasesInLocalStorage(newRecentlyViewedCases);
      } else {
        const newRecentlyViewedCases = [...recentlyViewedCasesInLocalStorage];
        if (
          recentlyViewedCasesInLocalStorage.length >= MAX_ITEMS_IN_LOCAL_STORAGE
        ) {
          newRecentlyViewedCases.pop();
        }
        setRecentlyViewedCasesInLocalStorage([
          entry,
          ...newRecentlyViewedCases,
        ]);
      }
      queryClient.invalidateQueries(getRecentlyViewedCasesQuery());
    },
    [recentlyViewedCasesInLocalStorage, setRecentlyViewedCasesInLocalStorage],
  );

  return {
    recentlyViewedCasesInLocalStorage,
    addRecentlyViewedCaseToLocalStorage,
  };
}

export default useRecentlyViewedCases;
