import { queryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import ms from 'ms';
import { RequestOpts } from '@/api/api-types';
import { CASE_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';
import { CaseCardProps } from '@/components/CaseCard';
import { fetchCaseAssigneesQuery } from './useCaseAssignees';
import { fetchContractCasesQuery } from './useContractCases';

export type Response = CaseCardProps[];

export type GetActiveCasesCardsQueryParams = {
  contractId?: string | null;
};

export function getActiveCasesCardsQuery(
  params?: GetActiveCasesCardsQueryParams,
  opts?: RequestOpts,
) {
  const queryKey = [CASE_SCOPES.getContractCases, params?.contractId];
  return queryOptions({
    queryKey,
    queryFn: () => fetchActiveCasesCards({ ...params }, opts),
    staleTime: 0,
    gcTime: ms('5m'),
  });
}

export async function fetchActiveCasesCards(
  params?: GetActiveCasesCardsQueryParams,
  opts: RequestOpts = {},
): Promise<Array<CaseCardProps>> {
  if (!params?.contractId) throw new TypeError('No contractId provided');

  let activeCaseCards: CaseCardProps[] = [];

  const activeContractCases = await fetchContractCasesQuery(
    {
      contractId: params.contractId,
      active: true,
    },
    opts,
  );

  for (const activeCase of activeContractCases) {
    const caseAssignees = await fetchCaseAssigneesQuery(
      {
        caseId: activeCase.caseId,
      },
      opts,
    );

    activeCaseCards.push({
      eventCase: activeCase,
      caseAssignees: caseAssignees,
    });
  }

  return Promise.resolve(activeCaseCards);
}

export function fetchActiveCasesCardsQuery(
  params?: GetActiveCasesCardsQueryParams,
  opts: RequestOpts = {},
): Promise<Array<CaseCardProps>> {
  return queryClient.ensureQueryData(getActiveCasesCardsQuery(params, opts));
}

export function useActiveCasesCards(
  params?: GetActiveCasesCardsQueryParams,
  options: Omit<ReturnType<typeof getActiveCasesCardsQuery>, 'queryKey'> = {},
): UseQueryResult<Array<CaseCardProps>> {
  return useQuery(
    Object.assign(getActiveCasesCardsQuery(params), {
      ...options,
    }),
  );
}
