/**
 * This module contains predicate functions related to loan cases.
 * Predicates are used to determine specific properties or conditions of loan cases.
 */

import { caseApi, orchestrationApi } from '@/api';
import { isAgreementCandidate } from '@/api/predicates/caseCalculation';

/**
 * Checks if the given code is a valid ChangeMethodCode from the caseApi.
 *
 * @param code - The code to check.
 * @returns True if the code is a valid ChangeMethodCode, false otherwise.
 */
export function isChangeMethodCode(
  code?: unknown,
): code is caseApi.ChangeMethodCode {
  return (
    typeof code === 'string' &&
    Object.values(caseApi.ChangeMethodCode).some((value) => value === code)
  );
}

/**
 * Checks if a loan case has an agreement candidate.
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns {boolean} - Returns true if the loan case has an agreement candidate, otherwise returns false.
 */
export function hasAgreementCandidate(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return Boolean(loanCase?.calculations?.some(isAgreementCandidate));
}

/**
 * Checks if a loan case is closed based on the presence of the close date.
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns {boolean} - Returns true if the loan case is closed, otherwise returns false.
 */
export function isCaseClosed(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return !!loanCase?.closeDate;
}

/**
 * Checks if a loan case is closed with closeReasonCode.Done
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns {boolean} - Returns true if the loan case is closed and rejected, otherwise returns false.
 */
export function isCaseClosedAndDone(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return Boolean(
    !!loanCase?.closeDate &&
      loanCase.closeReasonCode?.toLowerCase() ===
        orchestrationApi.CloseReasonCode.Done.toLowerCase(),
  );
}

/**
 * Checks if a loan case is closed with closeReasonCode
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns {boolean} - Returns true if the loan case is closed and not done, otherwise returns false.
 */
export function isCaseClosedAndNotDone(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return Boolean(
    !!loanCase?.closeDate &&
      loanCase.closeReasonCode?.toLowerCase() !==
        orchestrationApi.CloseReasonCode.Done.toLowerCase(),
  );
}

/**
 * Checks if a loan case is fully processed.
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns A boolean value indicating if the loan case is fully processed.
 */
export function isCaseFullyProcessed(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return Boolean(
    !!loanCase?.closeDate &&
      loanCase.caseStateDescription === caseApi.CaseStateCode.FullyProcessed,
  );
}

/**
 * Checks if a loan case is closed with closeReasonCode
 * @param {caseApi.EventCaseModel | null} loanCase - The loan case to be checked.
 * @returns {boolean} - Returns true if the loan case is closed and rejected, otherwise returns false.
 */
export function isCaseRejectedOrCancelled(
  loanCase?: caseApi.EventCaseModel | null,
): boolean {
  return Boolean(
    isCaseClosed(loanCase) &&
      loanCase?.closeReasonCode &&
      loanCase.closeReasonCode !== orchestrationApi.CloseReasonCode.Done &&
      [
        orchestrationApi.CloseReasonCode.AgreementRejected,
        orchestrationApi.CloseReasonCode.CalculationRejected,
        orchestrationApi.CloseReasonCode.Error,
      ]
        .map((value) => value.toLowerCase())
        .includes(loanCase.closeReasonCode.toLowerCase()),
  );
}

/**
 * Checks if the given case party is a contact person.
 * @param {caseApi.CasePartyModel} caseParty - The case party to check.
 * @returns {boolean} - True if the case party is a contact person, false otherwise.
 */
export function isContactPerson(caseParty?: caseApi.CasePartyModel): boolean {
  return caseParty?.partyRoleCode === caseApi.PartyRoleCode.ContactPerson;
}

/**
 * Checks if the given case party is a customer.
 * @param {caseApi.CasePartyModel} caseParty - The case party to check.
 * @returns {boolean} - True if the case party is a customer, false otherwise.
 */
export function isCustomer(caseParty?: caseApi.CasePartyModel): boolean {
  return caseParty?.partyRoleCode === caseApi.PartyRoleCode.Customer;
}
