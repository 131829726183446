import { Translation } from 'react-i18next';
import lazyWithPreload from 'react-lazy-with-preload';
import { isContractGenerationPhase } from '@/api/predicates/casePhase';
import BreadcrumbMatches from '@/components/BreadcrumbMatches';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const ContractGenerationPage = lazyWithPreload(
  async () => await import('./index'),
);
const PdfDocumentPreview = lazyWithPreload(
  async () => await import('@kk/ui/components/PdfDocumentPreview'),
);

function preloadRelatedRouteComponents() {
  PdfDocumentPreview.preload();
}

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sag/:caseId/aftalegenerering',
  loader: (args) => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader(args);
  },
  element: <ContractGenerationPage />,
  handle: ({ params, data }) => {
    const phase = data?.eventCase.phases?.find(isContractGenerationPhase);
    return {
      preload: async () =>
        await Promise.allSettled([
          ContractGenerationPage.preload(),
          loader({ params, opts: { priority: 'low' } }),
        ]).then(preloadRelatedRouteComponents),
      breadcrumb: {
        tag:
          phase && data?.eventCase ? (
            <BreadcrumbMatches.PhaseStatusTag
              phase={phase}
              eventCase={data.eventCase}
            />
          ) : null,
        label: (
          <span className="flex">
            <Translation>{(t) => t('contract.generation.title')}</Translation>
          </span>
        ),
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
