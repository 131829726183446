import React, { ButtonHTMLAttributes } from 'react';
import { NotificationType } from '@kk/ui/constants';
import { PressEvent } from '@react-types/shared';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Icon, { type IconProps, type IconType } from '../Icon';
import { Spinner } from '../Spinner/Spinner';

export type Theme =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'subtle'
  | 'ghost'
  | 'danger'
  | 'warning'
  | 'none';

export const defaultBtnClasses =
  'text-xs font-medium leading-4 h-8 px-4 disabled:cursor-default kunde:text-sm kunde:rounded-md kunde:sm:h-10 kunde:h-14 kunde:focus:outline-none kunde:focus:ring kunde:focus:ring-blue-60 kunde:focus:border-transparent';
export const compactBtnClasses = 'text-xs leading-3 h-6 px-2';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: Theme;
  isDisabled?: boolean; // TODO: rename?
  children?: React.ReactNode;
  loading?: boolean;
  label?: string;
  icon?: IconType;
  iconProps?: Omit<IconProps, 'type'>;
  rightAligned?: boolean;
  compact?: boolean;
  padding?: string; // TODO: replace with a twMerge approach for overwriting padding
  onPress?: (e: React.MouseEvent<HTMLButtonElement> | PressEvent) => void;
  forwardedRef?: React.Ref<HTMLButtonElement>;
}

export const mapNotificationType = (type: NotificationType): Theme => {
  switch (type) {
    case 'success':
      return 'subtle';
    case 'default':
    case 'info':
      return 'primary';
    case 'error':
      return 'danger';
    default:
      return type as Theme;
  }
};

export const buttonThemes: Record<Theme, string> = {
  default: clsx(
    'text-neutral-110 border-transparent disabled:text-neutral-60',
    'bg-neutral-10 hover:bg-neutral-40 active:bg-neutral-10 focus:bg-neutral-40 active:animate-active',
    'aria-expanded:bg-neutral-10 aria-expanded:animate-active',
    'kunde:text-neutral-200 kunde:bg-white kunde:border-neutral-40 kunde:hover:bg-neutral-10 kunde:active:bg-neutral-40 kunde:disabled:border-neutral-10 kunde:disabled:text-neutral-60',
  ),
  primary: clsx(
    'text-white border-transparent disabled:text-neutral-60',
    'bg-blue-100 hover:bg-blue-40 focus:bg-blue-40 active:bg-blue-100 disabled:bg-neutral-10',
    'enabled:aria-expanded:bg-blue-100',
    'kunde:hover:bg-blue-60 kunde:focus:bg-blue-100 kunde:focus:border-blue-10 kunde:active:bg-blue-110 kunde:disabled:bg-neutral-10',
  ),
  secondary: clsx(
    'text-blue-60 border-blue-60 focus-visible:border-transparent',
    'hover:bg-blue-5 enabled:active:bg-blue-10 disabled:bg-neutral-10 disabled:text-neutral-60 disabled:border-transparent',
    'kunde:text-blue-100 kunde:bg-teal-5 kunde:border-blue-10 kunde:hover:bg-blue-10 kunde:hover:border-blue-20 kunde:active:bg-blue-20 kunde:active:border-transparent kunde:disabled:bg-neutral-10 kunde:disabled:border-transparent kunde:disabled:text-neutral-60',
  ),
  tertiary: clsx(
    'text-blue-60 border-transparent',
    'hover:bg-blue-5 active:bg-blue-5 disabled:text-neutral-60 disabled:bg-transparent',
    'kunde:bg-white kunde:hover:bg-blue-5 kunde:active:bg-blue-5 kunde:disabled:bg-white',
  ),
  subtle: clsx(
    'text-neutral-110 border-transparent',
    'enabled:hover:bg-neutral-10 focus:bg-neutral-10 disabled:bg-transparent disabled:text-neutral-60',
    'kunde:text-neutral-200 kunde:bg-white kunde:hover:bg-[#172975]/10 kunde:active:bg-[#858AA1]/40 kunde:disabled:bg-white kunde:disabled:text-neutral-60',
  ),
  ghost: clsx(
    'text-neutral-200 border-transparent',
    'enabled:hover:bg-neutral-10 focus:bg-neutral-10 disabled:bg-transparent disabled:text-neutral-60',
    'kunde:text-neutral-200 kunde:bg-white kunde:hover:bg-[#172975]/10 kunde:active:bg-[#858AA1]/40 kunde:disabled:bg-white kunde:disabled:text-neutral-60',
  ),
  warning: clsx(
    'text-neutral-110 enabled:active:text-blue-110 disabled:text-neutral-60 border-transparent',
    'bg-orange-100 enabled:hover:bg-orange-60 enabled:active:bg-orange-110 disabled:bg-neutral-10',
    'kunde:text-neutral-200 kunde:active:text-neutral-200 kunde:disabled:text-neutral-60',
  ),
  danger: clsx(
    'text-white hover:text-neutral-200 disabled:text-neutral-60 border-transparent',
    'bg-red-100 enabled:hover:bg-red-60 enabled:active:bg-red-110 disabled:bg-neutral-10 enabled:active:text-white',
  ),
  none: clsx('border-0 p-1 group'),
};

export function Button({
  theme = 'default',
  icon,
  compact,
  rightAligned,
  label,
  children,
  loading,
  isDisabled,
  forwardedRef,
  padding,
  className,
  onPress,
  onClick,
  iconProps,
  ...rest
}: ButtonProps) {
  const buttonContent = (
    <>
      {icon ? (
        <Icon
          solidOnActive
          type={icon}
          size={compact ? 'tiny' : 'small'}
          {...iconProps}
          className={
            label || children
              ? rightAligned
                ? compact
                  ? 'ml-1'
                  : 'ml-2'
                : compact
                  ? 'mr-1'
                  : 'mr-2'
              : ''
          }
        />
      ) : null}
      {label ?? children}
    </>
  );

  return (
    <button
      ref={forwardedRef}
      className={twMerge(
        clsx(
          'laane:disabled:cursor-not-allowed flex items-center rounded border font-medium enabled:cursor-pointer',
          padding
            ? padding
            : compact
              ? [
                  compactBtnClasses,
                  rightAligned
                    ? { 'pl-2 pr-1': icon && (label ?? children) && !loading }
                    : { 'pl-1 pr-2': icon && (label ?? children) && !loading },
                ]
              : [
                  defaultBtnClasses,
                  rightAligned
                    ? { 'pl-4 pr-2': icon && (label ?? children) && !loading }
                    : { 'pl-2 pr-4': icon && (label ?? children) && !loading },
                ],
          !label || loading
            ? 'kunde:p-4 kunde:sm:p-2 kunde:md:px-4'
            : 'kunde:sm:py-2 kunde:sm:px-4 kunde:py-4 kunde:px-6',
          rightAligned && 'flex-row-reverse',
          buttonThemes[theme],
        ),
        className,
      )}
      disabled={isDisabled}
      {...rest}
      onClick={(e) => {
        onPress && onPress(e);
        onClick && onClick(e);
      }}
    >
      {loading ? (
        <Spinner
          className={clsx(
            'flex items-center justify-center',
            compact ? 'px-4' : 'px-1',
          )}
          size={compact ? 'small' : 'medium'}
        />
      ) : (
        buttonContent
      )}
    </button>
  );
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <Button {...props} forwardedRef={ref} />,
);
