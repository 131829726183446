import { lazy, Suspense } from 'react';
import { Icon } from '@kk/ui/components/Icon';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { useActiveAccount, useHasAccess } from '@/api/auth/claims';
import LogoLight from '@/assets/logo-darkmode.svg?react';
import Avatar from '@/components/Avatar';
import { Logo } from '@/components/Logo/Logo';
import { Search } from '@/components/Search/Search';
import flags from '@/flags';
import state from '@/state';
import { TopNavigation } from './private/TopNavigation';

const RoleSelector = lazy(() => import('@/devtools/RoleSelector'));

export function HeaderSkeleton() {
  const account = useActiveAccount();
  const { t } = useTranslation();
  const title = t('app.title');

  const avatar = (
    <Avatar
      data-testid="header-avatar"
      fullName={account?.name}
      isCurrentUser={true}
    />
  );

  return (
    <header className="bg-blue-110 fixed z-20 w-full" data-testid="main-header">
      <div id="error-portal" />
      <div className="flex h-14 items-center justify-between px-4">
        <a href="/">
          <figure className="text-white">
            <div className="flex items-center px-2 py-1">
              <LogoLight className="size-8" />
              <figcaption className="ml-3 inline-block">{title}</figcaption>
            </div>
          </figure>
        </a>
        <div className="flex items-center gap-3">
          {flags.enableNotifications ? (
            <Icon type="bell" size="small" className="text-white" />
          ) : null}
          {avatar}
        </div>
      </div>
    </header>
  );
}

function Header() {
  const account = useActiveAccount();
  const hasAccess = useHasAccess();
  const { devtoolsEnabled } = useSnapshot(state);

  const avatar = (
    <Avatar
      showTooltip
      className="hover:border-blue-60"
      data-testid="header-avatar"
      fullName={account?.name}
      isCurrentUser={true}
    />
  );

  return (
    <header className="bg-blue-110 fixed z-20 w-full" data-testid="main-header">
      <div id="error-portal" />
      <div className="flex h-14 items-center justify-between px-4">
        <div className="flex h-full items-center">
          <Logo />
          <div className="flex h-full items-center">
            <div className="bg-blue-60 mx-2 h-10 w-px" />
            <TopNavigation />
          </div>
        </div>
        {account ? (
          <div className="flex items-center gap-3">
            {hasAccess ? (
              <>
                <Search />
                {flags.enableNotifications ? (
                  <Icon type="bell" size="small" className="text-white" />
                ) : null}
              </>
            ) : null}
            {devtoolsEnabled ? (
              <Suspense fallback={avatar}>
                <RoleSelector
                  button={{
                    className: 'p-0',
                    theme: 'none',
                    children: avatar,
                  }}
                />
              </Suspense>
            ) : (
              avatar
            )}
          </div>
        ) : null}
      </div>
    </header>
  );
}

export default Header;
