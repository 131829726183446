import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import queryClient from '@/api/queryClient';

export type Response = Awaited<
  ReturnType<
    typeof loanApi.getCalculationByCalculationPathHolidaysAndHolidayUsage
  >
>;

export type GetCalculationHolidaysParams = {
  calculationPath?: string;
  holidayUsage: loanApi.HolidayUsage | undefined;
};

export function getCalculationHolidaysQuery(
  { calculationPath, holidayUsage }: GetCalculationHolidaysParams,
  opts: RequestOpts = {},
) {
  const queryKey = ['calculation-holidays', calculationPath, holidayUsage];
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!calculationPath) throw new TypeError('No calculationPath provided');
      if (!holidayUsage) throw new TypeError('No holidayUsage provided');
      return loanApi.getCalculationByCalculationPathHolidaysAndHolidayUsage(
        calculationPath,
        holidayUsage,
        {
          ...opts,
          signal,
        },
      );
    },
    staleTime: ms('1d'),
  });
}

export async function fetchCalculationHolidaysQuery(
  params: GetCalculationHolidaysParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCalculationHolidaysQuery(params, opts));
}

export function useCalculationHolidays(
  params: GetCalculationHolidaysParams,
  options: Omit<ReturnType<typeof getCalculationHolidaysQuery>, 'queryKey'>,
) {
  const query = getCalculationHolidaysQuery(params);
  return useQuery({
    ...query,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
  });
}
