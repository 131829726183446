import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { redirect } from 'react-router-dom';
import { ExtendedRouteObject } from '../types';
import { UrlLoanType } from './url';

export type Params = {
  companyId?: string;
  contractId?: string;
};

export default {
  id: 'loanpage-redirect',
  path: '/kunde/:companyId/aftale/:contractId',
  loader: ({ params }) => {
    assertParamsPrescence(params);
    const { companyId, contractId } = params;
    if (!companyId || !contractId) return null;
    const url = '/kunde/:companyId/aftale/:contractId/:loanType';
    const targetUrl = url
      .replace(':companyId', companyId.toString())
      .replace(':contractId', contractId.toString())
      .replace(':loanType', UrlLoanType.Laan);

    return redirect(targetUrl);
  },
} satisfies ExtendedRouteObject<Params, ReturnType<typeof redirect>>;
