import { Translation } from 'react-i18next';
import lazyWithPreload from 'react-lazy-with-preload';
import { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const LoanPage = lazyWithPreload(async () => await import('./index'));
const CasePage = lazyWithPreload(async () => await import('../CasePage'));
const CasesOverviewPage = lazyWithPreload(
  async () => await import('../CasesOverviewPage'),
);

function preloadRelatedRouteComponents() {
  CasePage.preload();
  CasesOverviewPage.preload();
}

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/:loanType',
  loader: (args) => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader(args);
  },
  element: <LoanPage />,
  handle: ({ params }) => {
    return {
      preload: async () =>
        await Promise.allSettled([
          LoanPage.preload(),
          loader({ params, opts: { priority: 'low' } }),
        ]).then(preloadRelatedRouteComponents),
      breadcrumb: {
        label: (
          <Translation>
            {(t) => t('loan_one') + ' ' + params.contractId}
          </Translation>
        ),
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
