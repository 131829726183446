import i18n from '@kk/ui/i18n';
import lazyWithPreload from 'react-lazy-with-preload';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const CasePage = lazyWithPreload(async () => await import('./index'));
const LoanPage = lazyWithPreload(async () => await import('../LoanPage'));
const CalculationPage = lazyWithPreload(
  async () => await import('../CalculationPage'),
);

function preloadRelatedRouteComponents() {
  LoanPage.preload();
  CalculationPage.preload();
}

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sag/:caseId',
  loader: (args) => {
    window.requestIdleCallback(preloadRelatedRouteComponents);
    return loader(args);
  },
  element: <CasePage />,
  handle: ({ data, params }) => {
    return {
      preload: async () =>
        await Promise.allSettled([
          CasePage.preload(),
          loader({
            params,
            opts: {
              priority: 'low',
            },
          }),
        ]).then(preloadRelatedRouteComponents),
      breadcrumb: {
        label: data?.eventCase.eventDescription
          ? `${i18n.t(`eventDescription.${data.eventCase.eventDescription}`)} ${params.caseId}`
          : params.caseId,
      },
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;
