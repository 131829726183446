import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchActiveCasesCardsQuery } from '@/api/hooks/case/useActiveCasesCards';
import { fetchContractCasesQuery } from '@/api/hooks/case/useContractCases';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';

export type Params = {
  companyId?: string;
  contractId?: string;
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'casesOverviewPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, companyId } = params;
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchActiveCasesCardsQuery({ contractId }, opts),
    fetchContractCasesQuery({ contractId, active: false }, opts),
  ]);
  assertNoLoaderErrors(id, data);
  const [company, loanInfo, activeCases, inactiveCases] = data;
  return {
    company,
    loanInfo,
    activeCases,
    inactiveCases,
  };
}

export const useCasesOverviewPageLoaderData = () => useRouteLoaderData(id);

export const useCaseOverviewPageParams = () => useParams<Params>();

export default loader;
