import { HolidayResponse } from '@kk/api/modules/loan';
import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { loanApi, orchestrationApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCalculationFromStorageQuery } from '@/api/hooks/loan/useCalculationFromStorage';
import { fetchCalculationHolidaysQuery } from '@/api/hooks/loan/useCalculationHolidays';
import { fetchCreateStandaloneCalculationpath } from '@/api/hooks/loan/useCreateStandaloneCalculationpath';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { fetchPartialRedemptionDatesQuery } from '@/api/hooks/loan/usePartialRedemptionDates';

export type Params = {
  companyId?: string;
  contractId?: string;
  caseId?: string;
};

export type Search = {
  id: string;
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'calculationPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, caseId, companyId } = params;

  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);

  assertNoLoaderErrors(id, data);
  const [company, loanInfo, eventCase, caseAssignees] = data;

  const originalCalculationPath = eventCase.calculations?.find(
    (calculation) => calculation.displayId === 0,
  )?.calculationPath;

  const calculationData = await handleSettledPromises([
    fetchPartialRedemptionDatesQuery({
      calculationPath: originalCalculationPath,
    }),
    ...(eventCase.calculations?.map((calculation) =>
      fetchCalculationFromStorageQuery(calculation),
    ) ?? []),
  ]);

  assertNoLoaderErrors(id, calculationData);

  try {
    const [partialRedemptionDates, ...calculations] = calculationData;
    let holidayData: HolidayResponse | undefined = undefined;
    if (
      eventCase.eventDescription ===
      orchestrationApi.EventDescriptionCode.NewLoan
    ) {
      // should this be done differently for new loans?
      const calculationPath = await fetchCreateStandaloneCalculationpath(
        {},
        opts,
      );
      holidayData = await fetchCalculationHolidaysQuery(
        {
          calculationPath,
          holidayUsage: loanApi.HolidayUsage.NewLoan,
        },
        opts,
      );
    }

    return {
      partialRedemptionDates,
      company,
      loanInfo,
      eventCase,
      caseAssignees,
      calculations,
      holidayData,
    };
  } catch (error) {
    console.error(error);
    throw new Error('Failed to load calculation page data');
  }
}

export const useCalculationPageLoaderData = () => useRouteLoaderData(id);

export const useCalculationPageParams = () => useParams<Params>();

export default loader;
