import { CalculationInfoResponse } from '@kk/api/modules/loan';
import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCalculationFromStorageQuery } from '@/api/hooks/loan/useCalculationFromStorage';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { isAgreementCandidate } from '@/api/predicates/caseCalculation';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {
  contractId?: string;
  companyId?: string;
  caseId?: string;
};

export const id = 'casePage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, caseId, companyId } = params;
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);
  assertNoLoaderErrors(id, data);

  const [company, loanInfo, eventCase, caseAssignees] = data;

  let agreementCandidate: CalculationInfoResponse | undefined = undefined;

  const candidate = eventCase.calculations?.find(isAgreementCandidate);
  if (candidate) {
    const calculation = await handleSettledPromises([
      fetchCalculationFromStorageQuery(
        { calculationPath: candidate.calculationPath },
        opts,
      ),
    ]);
    assertNoLoaderErrors(id, calculation);
    agreementCandidate = calculation.at(0);
  }

  return {
    company,
    loanInfo,
    eventCase,
    caseAssignees,
    agreementCandidate,
  };
}

export const useCasePageLoaderData = () => useRouteLoaderData(id);
export const useCasePageParams = () => useParams<Params>();

export default loader;
