import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchActiveCasesCardsQuery } from '@/api/hooks/case/useActiveCasesCards';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCreditQuery } from '@/api/hooks/loan/useCredit';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { UrlLoanType } from './url';

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export type Params = {
  companyId?: string;
  contractId?: string;
  loanType?: UrlLoanType;
};

export const id = 'loanPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, companyId } = params;

  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    params.loanType === UrlLoanType.Laan
      ? fetchLoanQuery({ contractId }, opts)
      : Promise.resolve(undefined),
    params.loanType === UrlLoanType.Byggekredit
      ? fetchCreditQuery({ contractId }, opts)
      : Promise.resolve(undefined),
    fetchActiveCasesCardsQuery({ contractId }, opts),
  ]);
  assertNoLoaderErrors(id, data);

  const [company, loanInfo, creditInfo, activeCases] = data;

  return {
    loanInfo,
    creditInfo,
    company,
    activeCases,
  };
}

export const useLoanPageLoaderData = () => useRouteLoaderData(id);

export const useLoanPageParams = () => useParams<Params>();

export default loader;
