import { queryOptions, useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { loanApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { LOAN_SCOPES } from '@/api/auth/permissions';
import queryClient from '@/api/queryClient';

export type Response = loanApi.CreditInfoResponse;

export type GetCreditQueryParams = {
  contractId?: string | null;
  loanNo?: number;
  calculationDate?: string;
};

export function getCreditQuery(
  params?: GetCreditQueryParams,
  opts: RequestOpts = {},
) {
  const queryKey = [
    LOAN_SCOPES.getCreditInfo,
    params?.contractId,
    params?.loanNo,
  ].filter(Boolean);
  return queryOptions({
    queryKey,
    queryFn: ({ signal }) => {
      if (!params?.contractId) throw new TypeError('No contractId provided');

      return loanApi.getGetcreditinfoByContractId(
        params.contractId,
        { calculationDate: params.calculationDate },
        { ...opts, signal },
      ) as unknown as Promise<Response>;
    },
    staleTime: ms('1d'),
    gcTime: ms('15m'),
    initialDataUpdatedAt: queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
}

export async function fetchCreditQuery(
  queryParams: GetCreditQueryParams,
  opts: RequestOpts = {},
) {
  return queryClient.fetchQuery(getCreditQuery(queryParams, opts));
}

export function useCredit(
  params: GetCreditQueryParams,
  options: Omit<ReturnType<typeof getCreditQuery>, 'queryKey'> = {},
) {
  const canGetCreditInfo = useHasPermission(LOAN_SCOPES.getCreditInfo);
  const query = getCreditQuery(params);
  return useQuery({
    ...query,
    staleTime: 0,
    ...options,
    enabled:
      !!params.contractId && canGetCreditInfo && options.enabled !== false,
  });
}
