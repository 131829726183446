import { loanApi } from '@/api';
import { generateUrl } from '../utils/generateUrl';

export enum UrlLoanType {
  Laan = 'laan',
  Byggekredit = 'byggekredit',
}

export const loanPageUrl = ({
  companyId,
  contractId,
  loanProduct,
}: {
  companyId: string | number | null | undefined;
  contractId: string | number | null | undefined;
  loanProduct: loanApi.ContractLoanTypeEnum | null | undefined;
}) => {
  return generateUrl('/kunde/:companyId/aftale/:contractId/:loanType', {
    companyId,
    contractId,
    loanType:
      loanProduct === loanApi.ContractLoanTypeEnum.Byggekredit
        ? UrlLoanType.Byggekredit
        : UrlLoanType.Laan,
  });
};
