import i18n from '@kk/ui/i18n';
import { loanApi, searchApi } from '@/api';
import { isEventDescription } from '@/api/hooks/loan/useCalculationFromStorage';
import { loanPageUrl } from '@/routes/LoanPage/url';
import { SafeTo } from '@/routes/types';
import { generateUrl } from '@/routes/utils/generateUrl';

export enum SearchCategories {
  Loans = 'loans',
  Companies = 'companies',
  Cases = 'cases',
}

export enum SearchTypes {
  Group = 'group',
  Entity = 'entity',
}

export type AutocompleteSearchResult = {
  name?: string | null;
  key?: string | null;
  type?: string | null;
} & (
  | {
      searchType: SearchTypes.Entity;
      group: SearchCategories;
      url: SafeTo;
      identifier: string;
      data: unknown;
    }
  | {
      searchType: SearchTypes.Group;
      count: number | null | undefined;
    }
);

interface Result {
  score: number;
  type: string;
  name: string;
  key: string;
}

export interface ResultSet {
  groupId: SearchCategories;
  hits: number;
  entities: Array<Result>;
}

export interface Autocomplete {
  searchKey: string;
  totalHits: number;
  results: Array<ResultSet>;
}

export function structureData(
  data: searchApi.SearchResponse,
): AutocompleteSearchResult[] {
  let structuredArray: AutocompleteSearchResult[] = [];

  if (data.companies && data.companies.length) {
    structuredArray.push({
      name: SearchCategories.Companies,
      searchType: SearchTypes.Group,
      key: SearchCategories.Companies,
      count: data.companyCount ?? data.companies.length,
    });
    for (const customerEntity of data.companies.slice(0, 5)) {
      structuredArray.push({
        name: customerEntity.legalName,
        identifier: `∙ ${customerEntity.companyNumber} ∙ CVR ${customerEntity.cvr}`,
        key: customerEntity.id,
        group: SearchCategories.Companies,
        searchType: SearchTypes.Entity,
        url: generateUrl('/kunde/:companyId/sager', {
          companyId: customerEntity.id,
        }),
        data: customerEntity,
      });
    }
  }

  if (data.loans && data.loans.length) {
    structuredArray.push({
      name: SearchCategories.Loans,
      searchType: SearchTypes.Group,
      key: SearchCategories.Loans,
      count: data.loanCount ?? data.loans.length,
    });
    for (const loanEntity of data.loans.slice(0, 9)) {
      structuredArray.push({
        name: loanEntity.contractNumber,
        key: loanEntity.contractNumber,
        type: loanEntity.loanType,
        group: SearchCategories.Loans,
        searchType: SearchTypes.Entity,
        identifier: `∙ ${loanEntity.companyLegalName}`,
        url: loanPageUrl({
          companyId: loanEntity.companyId,
          contractId: loanEntity.contractNumber,
          loanProduct: isByggekredit(loanEntity.loanType)
            ? loanApi.ContractLoanTypeEnum.Byggekredit
            : loanApi.ContractLoanTypeEnum.KKbasis,
        }),
        data: loanEntity,
      });
    }
  }

  if (data.cases && data.cases.length) {
    structuredArray.push({
      name: SearchCategories.Cases,
      searchType: SearchTypes.Group,
      key: SearchCategories.Cases,
      count: data.caseCount ?? data.cases.length,
    });
    for (const caseEntity of data.cases.slice(0, 5)) {
      const eventDescription = isEventDescription(caseEntity.eventDescription)
        ? i18n.t(`eventDescription.${caseEntity.eventDescription}`)
        : '';

      structuredArray.push({
        name: caseEntity.caseId,
        key: caseEntity.caseId,
        group: SearchCategories.Cases,
        searchType: SearchTypes.Entity,
        identifier: `∙ ${eventDescription} ∙ CVR ${caseEntity.primaryPartyCVR}`,
        url: generateUrl('/kunde/:companyId/aftale/:contractId/sag/:caseId', {
          companyId: caseEntity.primaryPartyCrmGuid,
          contractId: caseEntity.contractId,
          caseId: caseEntity.caseId,
        }),
        data: caseEntity,
      });
    }
  }

  return structuredArray;
}

export default structureData;

function isByggekredit(
  value: string | null | undefined,
): value is loanApi.ContractLoanTypeEnum.Byggekredit {
  return value === loanApi.ContractLoanTypeEnum.Byggekredit;
}
