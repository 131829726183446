import StatusTag from '@kk/ui/components/StatusTag';
import lazyWithPreload from 'react-lazy-with-preload';
import { caseApi } from '@/api';
import useMapPhaseStatus from '@/hooks/useMapPhaseStatus';
import type { ExtendedRouteObject } from '@/routes/types';
import loader, { id, type LoaderData, type Params } from './loader';

const CaseRecordPage = lazyWithPreload(async () => await import('./index'));

export default {
  id,
  path: '/kunde/:companyId/aftale/:contractId/sag/:caseId/dokument/:casePhaseCode/:recordName/:recordId',
  loader,
  element: <CaseRecordPage />,
  handle: ({ params, data }) => {
    const phase = data?.eventCase.phases?.find(
      (phase) => phase.casePhaseCode === params.casePhaseCode,
    );
    const hasPhaseCode =
      Boolean(params.casePhaseCode) &&
      params.casePhaseCode !== 'null' &&
      params.casePhaseCode !== 'undefined';

    return {
      preload: async () =>
        await Promise.allSettled([
          CaseRecordPage.preload(),
          loader({ params, opts: { priority: 'low' } }),
        ]),
      breadcrumb: [
        hasPhaseCode
          ? {
              label: data?.eventCase.phases?.find(
                (casePhase) =>
                  casePhase.casePhaseCode?.toLowerCase() ===
                  params.casePhaseCode?.toLowerCase(),
              )?.casePhaseDescription,
              tag: <PhaseStatusTag phase={phase} eventCase={data?.eventCase} />,
              to: `/kunde/${params.companyId}/aftale/${params.contractId}/sag/${params.caseId}#${params.casePhaseCode}`,
            }
          : null,
        {
          label: params.recordName ? decodeURIComponent(params.recordName) : '',
        },
      ].filter(Boolean),
    };
  },
} as const satisfies ExtendedRouteObject<Params, LoaderData>;

function PhaseStatusTag({
  phase,
  eventCase,
}: {
  phase?: caseApi.CasePhaseModel;
  eventCase?: caseApi.EventCaseModel;
}) {
  const mapPhaseToStatus = useMapPhaseStatus();
  return <StatusTag {...mapPhaseToStatus(phase, eventCase)} className="ml-2" />;
}
