import { z } from 'zod';

export const flagSchema = z.object({
  /**
   * Enables notifications.
   * @feature Feature 27259
   * @see {@link https://dev.azure.com/kommunekredit/KK%20Laaneportal/\_workitems/edit/27259}
   */
  enableNotifications: z.boolean(),

  /**
   * Enables construction credit in new loan calculation both standalone and in the new loan calculator.
   * @feature Test Case 40941
   * @see {@link https://kommunekredit.visualstudio.com/KK%20Laaneportal/\_workitems/edit/40941}
   */
  enableConstructionCreditInNewLoanCalculation: z.boolean(),
});

export type Flags = z.infer<typeof flagSchema>;

const config: Flags = {
  enableConstructionCreditInNewLoanCalculation: import.meta.env.DEV,
  enableNotifications: false,
};

const featureFlags = flagSchema.parse(config);

export default featureFlags;
