import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors, LoaderError } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchDocumentByRecordIdQuery } from '@/api/hooks/case/useDocumentByRecordId';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCalculationFromStorageQuery } from '@/api/hooks/loan/useCalculationFromStorage';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { isAgreementCandidate } from '@/api/predicates/caseCalculation';
import { isCustomerResponse } from '@/api/predicates/caseRecord';

export type Params = {
  companyId?: string;
  contractId?: string;
  caseId?: string;
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'contractAgreementPage' as const;

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);

  const { caseId, contractId, companyId } = params;
  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);

  assertNoLoaderErrors(id, data);
  const [company, loanInfo, eventCase, caseAssignees] = data;

  const agreementCandidate = eventCase.calculations?.find(isAgreementCandidate);

  if (!agreementCandidate) {
    throw new LoaderError(id, data);
  }

  const customerResponseRecord =
    eventCase.caseRecords?.find(isCustomerResponse);

  const relatedData = await handleSettledPromises([
    fetchCalculationFromStorageQuery({
      calculationPath: agreementCandidate.calculationPath,
    }),
    fetchDocumentByRecordIdQuery({
      recordId: customerResponseRecord?.recordId,
    }),
  ]);

  assertNoLoaderErrors(id, relatedData);
  const [calculationData, customerResponse] = relatedData;

  return {
    calculationPath: agreementCandidate.calculationPath,
    calculationData,
    loanInfo,
    company,
    eventCase,
    caseAssignees,
    customerResponse,
  };
}

export const useContractAgreementPageLoaderData = () => useRouteLoaderData(id);

export const useContractAgreementPageParams = () => useParams<Params>();

export default loader;
